import React from 'react';
import { Outlet } from 'react-router-dom';
import '../../styles/Menu.scss';
import '../../styles/App.scss';

const Layout = ({ rotation = 0, timing = 150, children }) => {
    return (
        <>
            <header>
            </header>
            <main className="main_container">
                <Outlet />
            </main>
        </>
    );
};

export default Layout;