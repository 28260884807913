import React, { useEffect, useState } from 'react';
//import axios from 'axios';
import { Helmet } from 'react-helmet';
import GetHome from '../components/GetHome';
import GalleryLib from '../components/GalleryLib';
import GalleryPreloader from '../components/GalleryPreloader';
import jsonData from '../components/gallery1.json';
import jsonData2 from '../components/gallery2.json';
import metaData from '../components/meta_data.json';
import '../styles/innerContent.scss';
import '../styles/Gallery.scss';

//const BASE_URL = 'https://api-alexdracing.ru/wp-json/wp/v2/media';
//const postId = 613;
//const POST_DATA = 'https://api-alexdracing.ru/wp-json/wp/v2/posts';

let screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;
let metaTitle, metaDescription, canonical;
let width, height;
let imgsLeft, imgsRight;
let selectedData;

const Gallery = () => {
    const [media, setMedia] = useState([]);
    const [totalImages, setTotalImages] = useState(0);
    const [isShowBox, setIsShowBox] = useState(false);
    const [postContent, setPostContent] = useState(false);
    const [allImages, setAllImages] = useState(0);
    const [zoomImg, setZoomImg] = useState(0);
    const [clickCount, setClickCount] = useState(1);
    const [imgSize, setImgSize] = useState({});
    const [imageStart, setImageStart] = useState(0);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [backToAll, setBackToAll] = useState(true);

    const newSize = Math.sqrt(screenWidth * screenHeight / (jsonData.length + jsonData2.length));

    const show = (e) => {
        setAllImages(1);
        if ((clickCount === 1) || (clickCount === 2)) {
            setClickCount(clickCount + 1);
            setButtonClicked(true);
        } else {
            setBackToAll(true);
            setButtonClicked(true);
            setClickCount(1);
            width = Math.round(newSize);
            height = Math.round(newSize) - 2.39;
            setImgSize({ width, height });
        }
    };

    const hide = (e) => {
        setAllImages(0);
        setClickCount(1);
        const btnAll = document.getElementsByClassName('allPhotos');
        btnAll[0].style.display = 'block';
        width = Math.round(newSize);
        height = Math.round(newSize) - 2.39;
        setImgSize({ width, height });
        setZoomImg(0);
    };

// const metaTitle = {postContent.acf.meta_title};
    // const metaDescription = postContent.acf.meta_description;
    //
    // let d, x1, x2;
    //
    // d = 4 * ((screenHeight * screenWidth) / totalImages);
    //
    // if (d > 0) {
    //     x1 = (-4 + Math.sqrt(d)) / 2;
    //     x2 = (-4 - Math.sqrt(d)) / 2;
    //
    //     width = Math.max(x1, x2);
    //     height = Math.max(x1, x2);
    // }

    const fetchData = async () => {
        try {
            let allMedia = [];
            const findPage = 'gallery';
            const metaPage = metaData.find((item) => item.page === findPage);
            setPostContent(metaPage);
            setTotalImages(jsonData.length + jsonData2.length);
            allMedia = [...allMedia, ...jsonData, ...jsonData2];
            setMedia(allMedia);
            setImgSize({ width, height });
            setTimeout(() => setIsShowBox(true));

            // let allMediaTest = [];
            // let currentPageTest = 1;
            // let totalPagesTest = 1;
            // let STEP_TEST = 100;
            // while (currentPageTest <= totalPagesTest) {
            //     const responseTest = await axios.get(`${BASE_URL}?parent=${postId}&per_page=${STEP_TEST}&page=${currentPageTest}`);
            //     const mediaPostsTest = responseTest.data;
            //
            //     if (currentPageTest === 1) {
            //         totalPagesTest = parseInt(responseTest.headers['x-wp-totalpages'], 10);
            //     }
            //
            //     allMediaTest = [...allMediaTest, ...mediaPostsTest];
            //     currentPageTest++;
            // }
            // setMediaTest(allMediaTest);
        } catch (error) {
            console.error('Error fetching media posts:', error);
        }
    };

    useEffect(() => {
        const displaySize = () => {
            screenWidth = window.innerWidth;
        };

        const handleOrientationChange = () => {
            if (window.orientation === 0 || window.orientation === 90 || window.orientation === -90) {
                setTimeout(() => displaySize(), 200);
            }
        };

        window.addEventListener('orientationchange', handleOrientationChange);

        fetchData();
    }, []);

    const handleClick = (event) => {
        setClickCount((prevClickCount) => prevClickCount + 1);
        firstClickZoom(event);
    };

    const handleClickImg = (event) => {
        setClickCount(clickCount + 1);
        firstClickZoom(event);
        setButtonClicked(false);
    };

    const firstClickZoom = (event) => {
        const dataId = event.target.dataset.id;
        imgsLeft = parseInt(dataId) - 28;
        imgsRight = parseInt(dataId) + 33;
        console.log(clickCount)
        if (imgsLeft < 1) {
            imgsLeft = 1;
        } else if (imgsRight > totalImages) {
            imgsRight = totalImages;
        }

        setZoomImg(1);
        const element = document.querySelector(`[data-id="${imgsLeft}"]`);

        if (clickCount === 1) {
            if (screenWidth > 767) {
                setBackToAll(false);
            }
            imgsLeft = parseInt(dataId) - 6;
            imgsRight = parseInt(dataId) + 2;

            if (imgsLeft < 1) {
                imgsLeft = 1;
            }
            if (imgsRight > totalImages) {
                imgsRight = totalImages;
            }
        } else if (clickCount === 2) {
            setBackToAll(true);
            const dataId = Number(event.target.dataset.set);
            setImageStart(dataId);
            hide();
        }
    };

    const fullScreen = media.map((post) => {
        const imageUrl = post.media_details.sizes.full.source_url;
        const isWideImage = post.media_details.width >= 1000;

        return (
            <div key={post.id} className="fullImgContainer">
                <img src={`${imageUrl}`} alt={post.title.rendered} loading="lazy" className={`fullImgItem ${isWideImage ? 'wide-image' : ''}`} />
            </div>
        );
    });

    const filteredImages = media
        .map((item, index) => ({ ...item, originalIndex: index }))
        .filter((item, index) => index >= imgsLeft && index <= imgsRight);

    const remainingImages = media
        .map((item, index) => ({ ...item, originalIndex: index }))
        .filter((item, index) => index + 1 < imgsLeft || index + 1 > imgsRight);

    const filteredData = [...filteredImages, ...remainingImages];

    const gridPhotos = filteredData.map((item, index) => (
        <div key={index} data-set={item.originalIndex} onClick={handleClickImg}>
            <img src={item.media_details.sizes.full.source_url} alt={`Фото ${index}`} />
        </div>
    ));

    const gridPhotosMob = media.map((item, index) => (
        <div key={index} data-set={index} onClick={handleClickImg}>
            <img src={item.media_details.sizes.full.source_url} alt={`Фото ${index}`} />
        </div>
    ));

    if (gridPhotos.length > 1) {
        selectedData = gridPhotos;
    } else {
        selectedData = gridPhotosMob;
    }

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:url" content={canonical} />
            </Helmet>
            <GalleryPreloader />
            <div id="sectionGallery" className={`${zoomImg ? 'overAuto' : 'zoomNone'}`}>
                <GalleryLib
                    photosSelect={{ imgsLeft, imgsRight }}
                    data={media}
                    animation={isShowBox}
                    full={fullScreen}
                    allImages={allImages}
                    click={clickCount}
                    grid={selectedData}
                    imageStart={imageStart}
                    onClickImage={handleClick}
                    imgSizes={imgSize}
                />
            </div>
            <button className={`allPhotos ${buttonClicked ? 'hidden' : ''}`} onClick={show}>
                Все фото
            </button>
            <button className={`allPhotos ${backToAll ? 'hidden' : ''}` } onClick={show}>
               Назад
            </button>
            <GetHome />
        </>
    );
};

export default Gallery;
