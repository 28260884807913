import React, {useEffect, useLayoutEffect, useState, useRef, useCallback} from 'react';
import GridLayout from "react-grid-layout";
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import '../styles/GridGallery.scss';

let screenW = window.innerWidth;
let rowHeight, cols;

const SmartyGrid = (props) => {
    const gridData = props.data; // данные о всех изображениях
    const imgId = props.diapason;
    const [visibleImages, setVisibleImages] = useState(18); // начальное количество видимых изображений
    const [widthGrid, setWidthGrid] = useState(screenW); // начальная ширина окна
    const galleryContainerRef = useRef(null); // ссылка на контейнер галереи

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                const newHeight = entry.contentRect.height;
                console.log('Новая высота блока gallery-container:', newHeight);
            }
        });

        if (galleryContainerRef.current) {
            observer.observe(galleryContainerRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    rowHeight = 200;
    cols = 6;
    if (screenW <= 500) {
        rowHeight = 94;
        cols = 3;
    }

    // Ограничиваем количество видимых изображений
    const currentGridData = gridData.slice(0, visibleImages);

    const layout = currentGridData.map((item, index) => {
        const actionIndex = index % 9;
        const actionIndexMob = index % 12;

        let w = 1;
        let h = 1;
        let x = index % cols;
        let y = Math.floor(index / cols);

        const desktopConfig = [
            { w: 2, h: 2, x: 0 },
            { w: 1, h: 1, x: 2 },
            { w: 1, h: 1, x: 3 },
            { w: 2, h: 2, x: 4 },
            { w: 2, h: 2, x: 0 },
            { w: 2, h: 2, x: 2 },
            { w: 1, h: 1, x: 2 },
            { w: 1, h: 1, x: 3 },
            { w: 2, h: 2, x: 4, y: 1 },
            { w: 1, h: 1, x: index % 6 },
        ];

        const mobileConfig = [
            { w: 1, h: 1, x: 0 },
            { w: 2, h: 2, x: 2 },
            { w: 1, h: 1, x: 0 },
            { w: 1, h: 1, x: 0 },
            { w: 1, h: 1, x: 1 },
            { w: 1, h: 1, x: 2 },
            { w: 2, h: 2, x: 0 },
            { w: 1, h: 1, x: 2 },
            { w: 1, h: 1, x: 2 },
            { w: 1, h: 1, x: 0 },
            { w: 1, h: 1, x: 1 },
            { w: 1, h: 1, x: 2 },
        ];

        const selectedConfig = screenW > 500 ? desktopConfig[actionIndex] : mobileConfig[actionIndexMob];

        x = selectedConfig.x;
        w = selectedConfig.w;
        h = selectedConfig.h;

        if (selectedConfig.y) {
            y = selectedConfig.y;
        }

        return {
            i: `${index}`,
            x: x,
            y: y,
            w: w,
            h: h,
        };
    });

    useEffect(() => {
        const displaySize = () => {
            screenW = window.innerWidth;
            setWidthGrid(screenW);
        };

        const handleOrientationChange = () => {
            setTimeout(() => displaySize(), 200);
        };

        window.addEventListener('orientationchange', handleOrientationChange);
        displaySize();

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    useLayoutEffect(() => {
        const elementToScroll = document.querySelector(`[data-set="${imgId.imgsLeft}"]`);

        if (elementToScroll) {
            elementToScroll.scrollIntoView({ behavior: "smooth" });
        }

    }, [imgId.imgsLeft]);

    // Функция для обработки скролла
    const handleScroll = useCallback(() => {
        const container = galleryContainerRef.current;

        // Если прокрутка происходит в контейнере
        if (container) {
            console.log(container.scrollTop); // Прокрутка внутри контейнера

            // Проверяем, что достигли конца контейнера
            if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
                setVisibleImages((prevVisibleImages) => {
                    if (prevVisibleImages < gridData.length) {
                        return prevVisibleImages + 18;
                    } else {
                        return prevVisibleImages; // Если все изображения показаны
                    }
                });
            }
        }
    }, [gridData.length]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('touchmove', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('touchmove', handleScroll);
        };
    }, [handleScroll]);

    return (
        <div className="gallery-container" ref={galleryContainerRef}>
            <GridLayout
                className="layout"
                layout={layout}
                cols={cols}
                rowHeight={rowHeight}
                width={widthGrid}
                isDraggable={false}
                isResizable={false}
                style={{ margin: '0', padding: '0 0 0 5px' }}
                margin={[1, 1]}
            >
                {currentGridData.map((item, index) => (
                    <div key={index} className={'gallery_img_container'}>{item}</div>
                ))}
            </GridLayout>
        </div>
    );
};

export default SmartyGrid;
