import React, {useEffect, useState} from 'react';
import GetHome from "../components/GetHome";

import '../styles/Team.scss';
import {Helmet} from "react-helmet";
import axios from "axios";
import Media from "../img/mediaImg.jpg";
import MainMenu from "../components/MainMenu";
import metaData from "../components/meta_data.json";

const POST_DATA = 'https://api-alexdracing.ru/wp-json/wp/v2/posts'
const BASE_URL = 'https://api-alexdracing.ru/wp-json/wp/v2/media';
const postId = 1281;
let metaTitle, metaDescription, canonical;
const step = 100;

const Team = () => {
    const [postContent, setPostContent] = useState(false);
    const [teamContent, setTeamContent] = useState([]);
    if (postContent) {
        metaTitle = postContent.title;
        metaDescription = postContent.description;
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const postsData = await axios.get(`${POST_DATA}/${postId}`)

                const findPage = 'team';
                const metaPage = metaData.find(item => item.page === findPage);
                setPostContent(metaPage)

                const teamData = postsData.data.acf.team_member
                setTeamContent(teamData);

            } catch (error) {
                console.error('Error fetching media posts:', error);
            }
        };
        fetchData();
    }, []);


    const temPhotos = teamContent.map((post, index) =>
        <div
            key={post.id}
            className={"teamElement"}>
            <img
                src={post.team_img}
                alt={'team member ' + index}
                loading="lazy"
            />
        </div>
    );

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:url" content={canonical} />
            </Helmet>

            <div id={"sectionTeam"}>
                <div className={'teamContainer'}>
                    {temPhotos}
                </div>
            </div>

            <MainMenu data={"innerPageMenu"}/>
            <GetHome />
        </>
    );
};

export default Team;