import React, {useEffect, useState} from 'react';
import GetHome from "../components/GetHome";
import SectionTitle from "../components/SectionTitle";
import BioGallery from "../components/BioGallery";
import axios from "axios";
import {Helmet} from "react-helmet";
import metaData from '../components/meta_data.json';

import LogoRT from "../img/rtLogo.png"
import '../styles/Biography.scss';
import '../styles/innerContent.scss';
import MainMenu from "../components/MainMenu";
import AnimatioLogo from "../components/AnimationLogo";

const POST_DATA = 'https://api-alexdracing.ru/wp-json/wp/v2/posts'
const postId = 1256;
let metaTitle, metaDescription, canonical;

const Biography = () => {

    const [postContent, setPostContent] = useState(false);
    const [bioGallery, setBioGallery] = useState([]);

    if (postContent) {
        metaTitle = postContent.title;
        metaDescription = postContent.description;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const postsData = await axios.get(`${POST_DATA}/${postId}?acf_format=standard`)
                setBioGallery(postsData.data.acf.gallery)

                const findPage = 'bio';
                const metaPage = metaData.find(item => item.page === findPage);
                setPostContent(metaPage)

            } catch (error) {
                console.error('Error fetching media posts:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:url" content={canonical} />
            </Helmet>

            <div id={"sectionBio"}>
                <div className={"bioGalery"}>
                   <AnimatioLogo />
                    <BioGallery data={bioGallery}/>
                </div>
                <div className={"bioContent"}>
                    <SectionTitle name={"биография"}/>
                    <div className={'contentBlock'}>
                        <p className={"specialTitle"}>Обучение:</p>
                        <ul>
                            <li>2012–2013 Maisach Driving Academy Germany</li>
                            <li>2014–2017 Broslavsky Racing Russia</li>
                            <li>2017–2018 FFF Racing Team Italy</li>
                            <li>2018–2020 Team Orange Drift School Japan</li>
                        </ul>
                    </div>
                    <div className={'contentBlock'}>
                        <p className={"specialTitle"}>Опыт участия:</p>
                        <ul>
                            <li>2017–2018 Russian Drift Series GP, RDS GP (Россия)</li>
                            <li>2017–2018 EEDC, Eastern European Drift Championship (Белоруссия)</li>
                            <li>2017 HGK Drift Challenge (Европа — Латвия)</li>
                            <li>2017–2018 D1 GP Asia Pacific (Россия)</li>
                            <li>2019–2020 G1GP Japan (Япония)</li>
                        </ul>
                    </div>

                    <div className={'rtLink'}>
                        <img src={LogoRT} alt="rt" width={'50px'} height={'42px'}/>
                        <p>Документальный фильм от международного телеканала RT Russia Today</p>
                        <a href="https://rtd.rt.com/films/come-drift-with-me/" target={'_blank'} className={'externalLink'}>Смотреть</a>
                    </div>
                </div>
            </div>

            <MainMenu data={"innerPageMenu"}/>
            <GetHome data={"top"}/>
        </>
    );
};

export default Biography;