import React, { useState, useEffect } from "react";
import '../styles/App.scss';

const AnimatioLogo = (data) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            // Определение текущей ширины и обновление состояния
            if (width === 0) {
                setWidth(100);
            } else {
                setWidth(0);
            }

            // Удаление классов и добавление новых
            const firstEl = document.querySelector('.first-el');
            const secondEl = document.querySelector('.second-el');

            firstEl.classList.remove('show-r', 'show-l');
            secondEl.classList.remove('show-r', 'show-l');

            if (width === 0) {
                firstEl.classList.add('show-l');
                secondEl.classList.add('show-r');
            } else {
                firstEl.classList.add('show-r');
                secondEl.classList.add('show-l');
            }
        }, 600); // Повторение каждые 5 секунд

        // Очистка интервала при размонтировании компонента
        return () => clearInterval(interval);
    }, [width]);

    return (
        <div className={`preload-bio ${data.source}`}>
            <div className={"logo-anim"}>
                <span className={'first-el '} style={{ width: width + '%' }}>
                </span>
                <span className={'second-el '} style={{ width: 100 - width + '%' }}>
                </span>
            </div>
        </div>
    );
};

export default AnimatioLogo;
