import React from 'react';

const SectionTitle = (props) => {
    return (
        <>
            <h1 className="page-title"> {props.name}</h1>
        </>
    );
};

export default SectionTitle;