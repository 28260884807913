import React, {useEffect, useState} from 'react';
import GetHome from "../components/GetHome";
import SectionTitle from "../components/SectionTitle";
import axios from "axios";

import '../styles/innerContent.scss';
import '../styles/Partners.scss';
import {Helmet} from "react-helmet";
import Media from "../img/mediaImg.jpg";
import MainMenu from "../components/MainMenu";
import metaData from "../components/meta_data.json";


const POST_DATA = 'https://api-alexdracing.ru/wp-json/wp/v2/posts'
const postId = 1288;
let metaTitle, metaDescription, canonical;

const Partners = () => {

    const [postContent, setPostContent] = useState(false);

    if (postContent) {
        metaTitle = postContent.title;
        metaDescription = postContent.description;
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const findPage = 'partners';
                const metaPage = metaData.find(item => item.page === findPage);
                setPostContent(metaPage)
            } catch (error) {
                console.error('Error fetching media posts:', error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:url" content={canonical} />
            </Helmet>

            <div id={"sectionPartners"}>
                <div className={"bioContent"}>
                    <SectionTitle name={"партнёры"}/>
                    <div className={'contentBlock'}>
                        <p> Медиа ресурс Российского чемпионата RDS GP - это более 100 млн. просмотров в год уникальной
                            платёжеспособной аудитории во всех медиа.</p>
                        <p> Наши принципы: Арт, Спорт, Инновации. Мы предлагаем уникальные маркетинговые возможности для
                            B2B и B2C брендов, которые будут обоснованы эконометрикой в рамках увеличения продаж и
                            маркетинговой стратегии бренда.
                        </p>
                        <p> Мы готовы к новым предложениям, специальным проектам и идеям. Мы стремимся создавать новые
                            маркетинговые решения для любых товаров и услуг. Мы с удовольствием готовы стать надёжным
                            партнёром в продвижении ваших брендов, товаров и услуг.
                        </p>

                        <div className={"director"}>
                            <p>Руководитель по работе с партнерами Евгений Чернышев</p>
                            <div className={'contact-der'}>
                                <a href={"tel:+79104760339"}>+7 910 476 03 39</a>
                                <a href={"https://t.me/noodlesrocknroll"} className={"messengerLinks"}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M2 6C2 3.79086 3.79086 2 6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6ZM15.9488 16.4887C16.32 14.4975 17.0512 10.1775 17.22 8.4C17.2259 8.31707 17.2225 8.22166 17.2196 8.14006C17.2169 8.06668 17.2147 8.00446 17.22 7.9725C17.2087 7.8825 17.175 7.75875 17.0625 7.66875C16.9275 7.55625 16.7138 7.53375 16.6238 7.53375C16.1963 7.53375 15.555 7.75875 12.4275 9.06375C11.3363 9.51375 9.15375 10.4587 5.86875 11.8875C5.34 12.1012 5.05875 12.3038 5.03625 12.5062C4.9959 12.8593 5.43485 12.9953 6.03688 13.1818C6.10633 13.2033 6.17795 13.2255 6.25125 13.2487C6.825 13.44 7.59 13.6537 7.995 13.665C8.355 13.6762 8.76 13.5188 9.21 13.215C12.2813 11.145 13.8562 10.0988 13.9575 10.0763C13.9663 10.075 13.9753 10.0736 13.9843 10.0722C14.0561 10.0609 14.1338 10.0488 14.1937 10.0988C14.2189 10.1254 14.2372 10.1577 14.247 10.193C14.2568 10.2283 14.2578 10.2655 14.25 10.3012C14.2053 10.4889 12.3796 12.1808 11.5455 12.9538C11.3293 13.1542 11.1797 13.2928 11.145 13.3275C11.0585 13.4155 10.9706 13.499 10.886 13.5794C10.3429 14.0952 9.93393 14.4836 10.8975 15.1163C11.3729 15.4295 11.751 15.6871 12.1327 15.9471C12.5187 16.2101 12.9084 16.4756 13.4062 16.8038C13.5327 16.8873 13.6536 16.9735 13.7715 17.0576C14.2412 17.3924 14.6622 17.6925 15.1838 17.6475C15.4762 17.6138 15.7913 17.3325 15.9488 16.4887Z"
                                          fill="white"/>
                                    </svg>
                                </a>
                                <a href={"https://wa.me/+79104760339"} className={'messengerLinks whatsapp'}>

                                </a>
                            </div>

                        </div>
                    </div>

                </div>
                <div className={"partnersPhoto"}>
                    <div className={"logoContainer"}>
                        <div className={"logoRow row-full"}>
                            <img src="/img/partners/adr-logo.svg" alt="adr" width={'100%'} height={"35px"}/>
                        </div>
                        <div className={"logoRow row-multy row-middle"}>
                            <img src="/img/partners/df-logo.svg" alt="sf" width={'278px'} height={"87px"}
                                 className={"df-logo"}/>
                            <img src="/img/partners/motul-logo.svg" alt="motul" width={'257px'} height={"70px"}
                                 className={"motul-logo"}/>
                        </div>
                        <div className={"logoRow row-multy"}>
                            <span>
                                <img src="/img/partners/kit-logo.svg" alt="kit" width={'133px'} height={"133px"}
                                     className={"kit-logo"}/>
                            </span>
                            <img src="/img/partners/oz-logo.svg" alt="oz" width={'283px'} height={"87px"}
                                 className={"oz-logo"}/>
                        </div>
                    </div>
                </div>
            </div>

            <MainMenu data={"innerPageMenu"}/>
            <GetHome addClass={"revers"} data={"top"}/>
        </>
    );
};

export default Partners;