import React, {useEffect, useState} from 'react';
import HomeBanner from "../components/HomeBanner";
import PreloaderHome from "../components/Preloader-home";
import axios from "axios";
import {Helmet} from "react-helmet";
import Media from "../img/mediaImg.jpg"
import metaData from "../components/meta_data.json";

const POST_DATA = 'https://api-alexdracing.ru/wp-json/wp/v2/posts'
const postId = 1283;
let metaTitle, metaDescription, canonical;

const HomePage = () => {
    const [postContent, setPostContent] = useState(false);

    if (postContent) {
        metaTitle = postContent.title;
        metaDescription = postContent.description;
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const findPage = 'main';
                const metaPage = metaData.find(item => item.page === findPage);
                setPostContent(metaPage)
            } catch (error) {
                console.error('Error fetching media posts:', error);
            }
        };
        fetchData();
    }, []);

    const sessionPreloader = sessionStorage.getItem("preloader");
    let elementClass = 'show-section';

    if (!sessionPreloader) {
        elementClass = 'hide-section';
    }

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:url" content={canonical} />
            </Helmet>

            {sessionPreloader ? '' : <PreloaderHome/>}

            <HomeBanner hide={elementClass}/>
        </>
    );
};

export default HomePage;