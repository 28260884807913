import {React, useState, useEffect} from 'react';



const GalleryPreloader = (props) => {


    return (
        <div className={"galleryPreloader"}>

        </div>
    );
};

export default GalleryPreloader;