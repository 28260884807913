import React, {useEffect, useState} from 'react';
import GetHome from "../components/GetHome";
import SectionTitle from "../components/SectionTitle";
import { Scrollbars } from 'react-custom-scrollbars-2';

import '../styles/innerContent.scss';
import '../styles/Press.scss';
import axios from "axios";
import {Helmet} from "react-helmet";
import Media from "../img/mediaImg.jpg";
import MainMenu from "../components/MainMenu";
import metaData from "../components/meta_data.json";

const POST_DATA = 'https://api-alexdracing.ru/wp-json/wp/v2/posts'
const postId = 1279;
let metaTitle, metaDescription, canonical;

const Press = () => {

    const [postContent, setPostContent] = useState(false);

    if (postContent) {
        metaTitle = postContent.title;
        metaDescription = postContent.description;
    }

    useEffect(() => {

        const fetchData = async () => {
            try {
                const findPage = 'press';
                const metaPage = metaData.find(item => item.page === findPage);
                setPostContent(metaPage)
            } catch (error) {
                console.error('Error fetching media posts:', error);
            }
        };
        fetchData();
    }, []);

    const pressLinks =[
        {id:1, site: 'Чемпионат.ком', text: 'Александр Дмитренко во всех попытках набирал более 90 баллов, итоговым же результатом стали 98,1 балл и третье место в зачёте', link: "https://www.championat.com/auto/article-3314321-obzor-pjatogo-etapa-rossijskoj-drift-serii-v-rjazani.html"},
        {id:2, site: 'Sport.ru', text: 'Российский гонщик Александр Дмитренко рассказал, как попал в дрифт и какие цели ставит на сезон', link: "https://www.sport.ru/autosport/Aleksandr_Dmitrenko_V_drift_ya_vlyubilsya_ne_srazu/article358265/ "},
        {id:3, site: 'Autosport.com.ru', text: 'Один из самых ярких дебютантов этого сезона РДС Александр Дмитренко', link: "https://autosport.com.ru/russia/drift/46570-kubok-chempionov-rds-samoe-vremya-otkatat-na-polnuyu-moshch "},
        {id:4, site: 'Чемпионат.ком', text: 'Побороться — абсолютно! Больше того, я буду очень стараться стать лидером нашего чемпионата', link: "https://www.championat.com/auto/news-2822602-dmitrenko-plany-na-rds-2017---borba-za-titul.html "},
        {id:5, site: 'Rambler Sport', text: 'Дмитренко - после этапа в Рязани прямо почуял запах подиума. Об этом сообщает «Рамблер» ', link: "https://sport.rambler.ru/other/37147800-dmitrenko-posle-etapa-v-ryazani-pryamo-pochuyal-zapah-podiuma/"},
        {id:6, site: 'Raceportal', text: ' Замыкает ТОП-5 квалификационных заездов Александр Дмитренко, заявившийся на 6 этап от команды «Fresh Auto»', link: "http://raceportal.ru/russian-motorsports/drift/russian-drift-series/item/674-zavershilsya-predposlednij-etap-rossijskoj-drift-serii-na-trasse-nring-v-nizhnem-novgorode-sobravshij-na-svoikh-tribunakh-2200-lyubitelej-drifta.html"},
        {id:7, site: 'Drive2', text: 'В целом, было "звездно". Собрались "лучшие из лучших" и "достойнейшие из достойнейших". В заездах Кубка Чемпионов участвовали пилоты из Москвы и Санкт-Петербурга, Одессы и Минска, Тюмени и Красноярска, Владивостока и Барнаула, Милана и Краснодара. Некоторых из них я уже упомянула, но вот поконкретнее: Александр Дмитренко…', link: "https://www.drive2.ru/b/487365127155220546/"},
        {id:8, site: 'Drom.ru', text: 'В этом году в Кубке Чемпионов приняли участие: Александр Дмитренко…', link: "https://www.drom.ru/info/autosport/rds-56013.html"},
        {id:9, site: 'Tornaditrikes', text: 'Замыкает ТОП-5 квалификационных заездов Александр Дмитренко, заявившийся на 6 этап от команды «Fresh Auto»', link: "https://tornadotrikes.ru/category/photos/"},
        {id:10, site: 'Drom.ru', text: 'В этом году в Кубке Чемпионов приняли участие: Александр Дмитренко…', link: "https://www.drom.ru/info/autosport/rds-56013.html"},
        {id:11, site: 'Vdrifte.ru', text: 'Да, это был очень непростой и не самый лучший раунд для нашей команды...скоро мы все расскажем и покажем гораздо подробнее', link: "https://vdrifte.ru/news/1790555871764507186/"},
    ];


    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:url" content={canonical} />
            </Helmet>
            <div id={"sectionPress"}>
                <div className={"pressPhoto"}>
                </div>
                <div className={"bioContent"}>
                    <SectionTitle name={"Пресса"}/>
                    <div className={'contentBlock'}>
                      <Scrollbars style={{ height: 430 }} className={"pressCotainer"} renderTrackVertical={props => <div {...props} className="verticalLine"/>} renderThumbVertical={props => <div {...props} className="verticalThumb"/>}>
                          <ul className={"nonStyle"}>
                              {
                                  pressLinks.map(function(item){
                                      return <li key={item.id}>
                                          <p><span>{item.site}: </span>{item.text}</p>
                                          <a href={`${item.link}`} className={'readMore'} target={"_blank"}>Читать</a>
                                      </li>
                                  })
                              }
                          </ul>
                      </Scrollbars>
                    </div>
                </div>
            </div>
            <MainMenu data={"innerPageMenu"}/>
            <GetHome data={"top"} />
        </>
    );
};

export default Press;