import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "rgb(113 75 238 / 90%)", }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#714BEE", opacity: '0.9', }}
            onClick={onClick}
        />
    );
}

const GallerySlider = ({data, imageStart}) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: imageStart,
        autoplay: false,
        onLazyLoad: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const fullSliderData = data;

    return (
        <div className={"gallerySliderContainer"}>
            <Slider {...settings}>
                {fullSliderData}
            </Slider>
        </div>
    );
};

export default GallerySlider;