import React, {useState, useEffect} from 'react';
import GallerySlider from "./GallerySlider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SmartyGrid from "./SmartyGrid";
import MainMenu from "./MainMenu";

let screenWidth = window.innerWidth
const screenHeight = window.innerHeight

const GalleryLib = (props) => {


    const imagesMedia = props.data;
    const animation = props.animation;
    const fullData = props.full;
    const [showImg, setshowImg] = useState(0);
    const allImagesShow = props.allImages;
    const clickCount = props.click;
    const gridData = props.grid;
    const gridDataMob = props.gridMob;
    const diapason = props.photosSelect;
    const imageStart = props.imageStart;
    const imgSize = props.imgSizes;

    //console.log(imagesMedia.length)

    const [images, setImages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const imagesPerPage = 25;
    const [showMenu, setShowMenu] = useState(0);

    useEffect(() => {
        if (screenWidth <= 767) {
            setShowMenu(1)
        }
        const displaySize = () => {
            screenWidth = window.innerWidth

        };

        const handleOrientationChange = () => {
            if (window.orientation === 0) {
                setTimeout(() => displaySize(), 200);

            } else if (window.orientation === 90 || window.orientation === -90) {
                setTimeout(() => displaySize(), 200);
            }
        };

        if (allImagesShow & clickCount === 1 & screenWidth > 767 || allImagesShow & clickCount === 2 & screenWidth > 767) {
            const loadNextPage = () => {
                const startIndex = (currentPage - 1) * imagesPerPage;
                const endIndex = startIndex + imagesPerPage;
                const newImages = imagesMedia.slice(startIndex, endIndex);
                setImages(prevImages => [...prevImages, ...newImages]);
                setCurrentPage(prevPage => prevPage + 1);
            };

            if (images.length < imagesMedia.length) {
                const timeoutId = setTimeout(loadNextPage, 150); // Ждем 1 секунду перед загрузкой следующей порции
                return () => clearTimeout(timeoutId); // Очищаем таймаут при размонтировании компонента
            }
        }

    }, [images, imagesMedia, currentPage, allImagesShow, clickCount, screenWidth]);

    const handleImageClick = (imageData) => {
        // Вызываем функцию handleClickImg из пропсов с передачей данных о картинке
        props.onClickImage(imageData);
    };
    //console.log(images)
    const galData = images.map((post, index) =>
        <img
            key={post.id}
            data-id={index + 1}
            className={"img-loaded"}
            src={post.media_details.sizes.thumbnail.source_url}
            alt={post.title.rendered}
            style={{maxWidth: imgSize.width, maxHeight: imgSize.height}}
            loading="lazy"
            onClick={handleImageClick}
        />
    );

    if (animation && allImagesShow) {
        setTimeout(() => setshowImg(1), 100);
    }


    if (allImagesShow & clickCount === 1 & screenWidth > 767) {
        return (
            <div className={`galleryContainer ${showImg ? "block-show" : "block-hide"}`}>
                {galData}
            </div>
        );
    } else if (allImagesShow & clickCount === 1 & screenWidth <= 767 || clickCount === 2) {
        return (
            <>
                <SmartyGrid data={gridData} diapason={diapason}/>
                <MainMenu data={`innerPageMenu ${showMenu ? 'showMenu' : ''}`}/>
            </>
        );
    }
    return (
        <>
            <GallerySlider data={fullData} imageStart={imageStart}/>
        </>
    );


};

export default GalleryLib;