import React, {useState, useEffect, useRef} from 'react';

import '../styles/preloader-home.scss';
import AnimatioLogo from "./AnimationLogo";

const PreloaderHome = () => {

    const preImgRef = useRef(null);
    const [img] = useState(['preloader1.webp', 'preloader2.webp']);
    const [imgVal, setImgVal] = useState(img[0]);
    const [activeState, setActiveState] = useState(false);

    useEffect(() => {
        const hasSeenPreloader = sessionStorage.getItem('hasSeenPreloader');

        if (!hasSeenPreloader) {
            setActiveState(true); // Показываем прелоадер только если пользователь его еще не видел
        }

        const getBonus = () => {
            const imgValue = img[Math.floor(Math.random() * img.length)];
            setImgVal(imgValue);
            const preImg = preImgRef.current;
            if (imgValue === 'preloader1.webp') {
                preImg.classList.add('scaleCustom');
            } else {
                preImg.classList.remove('scaleCustom');
            }
        };

        const preloaderOff = () => {
            setActiveState(false);
            sessionStorage.setItem('hasSeenPreloader', 'true'); // Сохраняем информацию о просмотре прелоадера в sessionStorage
            // Дополнительные действия после скрытия прелоадера
        };

        const timeout = setTimeout(() => {
            preloaderOff();
        }, 4000); // Прелоадер исчезнет через 3 секунды

        getBonus(); // Вызываем getBonus при монтировании компонента

        return () => {
            clearTimeout(timeout); // Очищаем таймаут и обработчик при размонтировании компонента
        };
    }, [img]);

    const preloaderOff1 = (preloader) => {
        sessionStorage.setItem('hasSeenPreloader', 'true');
        preloader.classList.add('hide');
    }

    const handleScroll = event => {
        const preloader = document.getElementById('preloaderHome');
        if (event.deltaY > 50) {
            if (preloader.classList.contains('hide')) {
                console.log("preloader hide")
            } else {
                preloaderOff1(preloader);
            }
        }
    }

    return (
        <div className={`preloader ${activeState ? 'preshow' : 'hide'}`} id="preloaderHome"
             style={{backgroundImage: 'url(/img/' + imgVal + ')'}} ref={preImgRef} onWheel={handleScroll}>
            <AnimatioLogo source={'homePre'}/>
            <div className="progress-bar">
              <span className="bar">
                <span className="progress"></span>
              </span>
            </div>
        </div>
    );
};

export default PreloaderHome;