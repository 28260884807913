import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {Helmet} from "react-helmet";
import ReactPlayer from 'react-player';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import metaData from '../components/meta_data.json';

import GetHome from "../components/GetHome";
import '../styles/innerContent.scss';
import '../styles/Video.scss';

import Logo from "../img/logo.svg"

import 'swiper/css';
import 'swiper/css/navigation';
import AnimatioLogo from "../components/AnimationLogo";


const POST_DATA = 'https://api-alexdracing.ru/wp-json/wp/v2/posts'
const postId = 1286;
let metaTitle, metaDescription, canonical;
let screenWidth = window.innerWidth
let screenHeight = window.innerHeight
let screenHcustom = screenHeight;
let controls = false;

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "rgb(113 75 238 / 90%)", }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#714BEE", opacity: '0.9', }}
            onClick={onClick}
        />
    );
}

const Video = () => {
    const [postContent, setPostContent] = useState(false);
    const [videos, setVideos] = useState([]);
    const [isPlaying, setIsPlaying] = useState([]);
    const [played, setPlayed] = useState([]);
    const [duration, setDuration] = useState([]);
    const playerRefs = useRef(Array(10).fill(null).map(() => React.createRef()));
    const [allVideo, setAllVideo] = useState(0);
    const [videoStart, setVideoStart] = useState(0);
    const [videoLoading, setVideoLoading] = useState(true);
    const [widthVideo, setWidthVideo] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [muted, setMuted] = useState(true);

    function show(e) {
        setAllVideo(0);
        //e.target.style.display = "none"
    }

    if (postContent) {
        metaTitle = postContent.title;
        metaDescription = postContent.description;
    }

    useEffect(() => {

        const displaySize = () => {
            screenWidth = window.innerWidth
            screenHeight = window.innerHeight
            setWidthVideo([screenWidth, screenHeight])
        };

        const handleOrientationChange = () => {
            if (window.orientation == 0) {
                setTimeout(() => displaySize(), 200);

            } else if (window.orientation === 90 || window.orientation === -90) {
                setTimeout(() => displaySize(), 200);
            }
        };

        // Добавляем слушатель события orientationchange
        window.addEventListener('orientationchange', handleOrientationChange);
        displaySize();


        trackTarget();
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const postsData = await axios.get(`${POST_DATA}/${postId}`)

            const findPage = 'video';
            const metaPage = metaData.find(item => item.page === findPage);
            setPostContent(metaPage)

            const videoData = postsData.data.acf.add_video_link.map(video => ({
                url: video.video_link, // Предполагается, что в объекте видео из API есть свойство 'acf.video_url'
                video_type: video.video_type,
                video_img: video.video_img,
                img_url: video.img_url,
            }));

            setVideos(videoData);
            const initialIsPlaying = new Array(videoData.length).fill(false);
            initialIsPlaying[0] = true; // Устанавливаем первый элемент в true
            setIsPlaying(initialIsPlaying);
            setPlayed(new Array(videoData.length).fill(0));
            setDuration(new Array(videoData.length).fill(0));

        } catch (error) {
            console.error('Error fetching media posts:', error);
        }
    };

    const trackTarget = (index) => {
        for (let e of document.querySelectorAll('input[type="range"].slider-progress.progress-' + index)) {
            e.style.setProperty('--value', e.value);
            e.style.setProperty('--min', e.min == '' ? '0' : e.min);
            e.style.setProperty('--max', e.max == '' ? '100' : e.max);
            e.addEventListener('input', () => e.style.setProperty('--value', e.value));
        }
    }

    const handlePlayPause = (index) => {
        const updatedIsPlaying = [...isPlaying]; // Создаем копию массива состояний воспроизведения
        updatedIsPlaying[index] = !updatedIsPlaying[index]; // Обновляем состояние воспроизведения для конкретного видео-плеера
        setIsPlaying(updatedIsPlaying); // Устанавливаем новое состояние воспроизведения
    };

    const toggleMute = (index) => {
        setMuted(!muted);
    };

    const handleSeek = (value, index) => {

        const updatedPlayed = [...played]; // Создаем копию массива played
        updatedPlayed[index] = value; // Обновляем значение только для конкретного индекса видео
        setPlayed(updatedPlayed); // Устанавливаем новое состояние played

        if (playerRefs.current[index].current) {
            playerRefs.current[0].current.seekTo(value, 'fraction');
        }
    };

    const handleProgress = (state, index) => {
        const updatedPlayed = [...played];
        updatedPlayed[index] = state.played;
        setPlayed(updatedPlayed);
        trackTarget(index);
    };


    const handleClickImg = (event) => {
        setAllVideo(0);
        const dataId = Number(event.target.dataset.set);
        setVideoStart(dataId)
        setVideoLoading(true);
    };

    const gridVideo = videos.map((item, index) =>
        <div key={index} data-set={index} onClick={handleClickImg} className={'videoGridContainer'}>
            <img
                src={item.img_url}
                alt={`Фото ${index}`}
                loading="lazy"
            />
        </div>
    );

    const videoSlides = videos.map((video, index) => {
        const videoType = video.video_type;

        if (videoType === 'local_file') {
            screenHcustom = 'auto';
            controls = false;
        } else {
            screenHcustom = screenHeight;
            controls = true;
        }

        return (
            <div className={'videoContainer'} key={index}>
                <ReactPlayer
                    ref={playerRefs.current[index]}
                    url={video.url}
                    playing={isPlaying[index]}
                    onProgress={(state) => handleProgress(state, index)}
                    onReady={() => setVideoLoading(false)}
                    controls={controls}
                    volume={1}
                    muted={muted}  // Управляем звуком
                    loop={true}
                    width={widthVideo[0]}
                    height={widthVideo[1]}
                    playsinline={true}
                    onClick={() => handlePlayPause(index)}
                />
                {!controls && (
                    <div className="custom-controls">
                        <button onClick={() => handlePlayPause(index)}
                                className={`play_btn ${isPlaying[index] ? 'pause' : 'play'}`}></button>
                        <input
                            className={'styled-slider slider-progress progress-' + index}
                            type="range"
                            min={0}
                            max={1}
                            step="any"
                            value={played[index]}
                            onChange={(e) => handleSeek(parseFloat(e.target.value), index)}
                        />
                        <button onClick={() => toggleMute(index)}
                                className={`mute_btn ${muted ? 'muted' : 'unmuted'}`}>
                        </button>
                    </div>
                )}
            </div>
        )
    });


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: videoStart,
        autoplay: false,
        onLazyLoad: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (oldIndex, newIndex) => {
            // Обновляем текущий слайд
            setCurrentSlide(newIndex);
            const updatedPlay = [...isPlaying];
            updatedPlay[oldIndex] = false;
            updatedPlay[newIndex] = true;

            // Ставим предыдущее видео на паузу
            setIsPlaying(updatedPlay);
        }
    };

    return (
        <>
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:url" content={canonical} />
            </Helmet>

            <div className={`loader ${videoLoading ? '' : 'hidden'}`}>
                <AnimatioLogo source={'homePre'} />
            </div>

            <div id={"sectionVideo"}>
                <Slider {...settings}
                    className="mySwiper"
                >
                    {videoSlides}
                </Slider>
            </div>

            {/*<a href={"https://www.youtube.com/@AlexDProDrift"} className={'allPhotos allvideo'} target={"_blank"}>Все видео</a>*/}
            <GetHome addClass={"video_home"} data={"top"}/>
        </>
    );
};

export default Video;