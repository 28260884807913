import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from "./pages/HomePage";

import Layout from "./components/layout/Layout";
import Biography from "./pages/Biography";
import Team from "./pages/Team";
import Partners from "./pages/Partners";
import ErrorPage from "./pages/ErrorPage";
import Press from "./pages/Press";
import Contacts from "./pages/Contacts";
import Gallery from "./pages/Gallery";
import Video from "./pages/Video";

export default function App() {

    useEffect(() => {
        const displaySize = () => {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = window.visualViewport.height * 0.01;

            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        const handleOrientationChange = () => {

                setTimeout(() => displaySize(), 200);

        };

        // Добавляем слушатель события orientationchange
        window.addEventListener('orientationchange', handleOrientationChange);

        displaySize();

    }, []);
    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/"element={<Layout />}>
                        <Route index element={<HomePage />} />
                        <Route path="bio" element={<Biography />} />
                        <Route path="team" element={<Team />} />
                        <Route path="gallery" element={<Gallery />} />
                        <Route path="video" element={<Video />} />
                        <Route path="partners" element={<Partners />} />
                        <Route path="press" element={<Press />} />
                        <Route path="contacts" element={<Contacts />} />

                    </Route>
                    <Route path="*" element={<ErrorPage/>}/>
                </Routes>
            </div>
        </Router>
    );
}

