import React from 'react';
let screenWidth = window.innerWidth

const GetHome = (props) => {
    const handleAddToLocalStorage = () => {
        if (screenWidth > 767) {
            localStorage.setItem('menu', '1');
        }

    };
    let top = props.data;

    return (
        <div className={props.addClass}>
            <a href="/" className={`goHome ${top}`}  onClick={handleAddToLocalStorage}>Главная</a>
        </div>
    );
};

export default GetHome;