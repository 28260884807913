import React, {useEffect, useState} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

const BASE_URL = 'https://api-alexdracing.ru/wp-json/wp/v2/media';
const postId = 1256;

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "rgb(113 75 238 / 90%)", }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "#714BEE", opacity: '0.9', }}
            onClick={onClick}
        />
    );
}

const BioGallery = (props) => {

    const media = props.data;

    const galleryBio = media.map((post, index) =>
        <div key={index}>
            <img
                src={post}
                alt={`Биография фото ${index}`}
                loading="lazy"
                className={'fullImgItem'}/>
        </div>
    );

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        onLazyLoad: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    return (
        <Slider {...settings}>
            {galleryBio}
        </Slider>
    );
};

export default BioGallery;