import React, {useState, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import LogoMain from "../img/logo.svg"

const MainMenu = (props) => {

    const innerClass = props.data;
    const [active, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!active);
    };

    useEffect(() => {
        const savedData = localStorage.getItem('menu');
        if (savedData == 1) {
            setActive(true);
            localStorage.setItem('menu', '0');
        }
    }, []);

    const menuLinks =[
        {id:1, name: 'Биография', link: "/bio"},
        {id:2, name: 'Команда', link: "/team"},
        {id:3, name: 'Галерея', link: "/gallery"},
        {id:4, name: 'Видео', link: "/video"},
        {id:5, name: 'Партнёры', link: "/partners"},
        {id:6, name: 'Пресса', link: "/press"},
        {id:7, name: 'Контакты', link: "/contacts"}
    ];

    return (
        <div id="menu1" className={`desctop_open ${innerClass} ${active ? "expanded" : ""}`}  onClick={toggleClass}>

            <div className="hamburger">
                <svg width="45" height="15" viewBox="0 0 45 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="45" height="3" rx="1" fill="white"/>
                    <rect y="6" width="45" height="3" rx="1" fill="white"/>
                    <rect x="7" y="12" width="38" height="3" rx="1" fill="white"/>
                </svg>



            </div>
            <div className="menu-inner">
                <ul>
                    {
                        menuLinks.map(function(item){
                            return <li key={item.id}>
                                <NavLink to={`${item.link}`}>{item.name}</NavLink>
                            </li>
                        })
                    }
                </ul>

                <div className="main-social social-menu">
                    <div className={"logoMenu"}>
                        <a href={"/"}>
                            <img src={LogoMain} alt="main logo"/>
                        </a>
                    </div>
                    <ul>
                        <li><a aria-current="page" className="telegram active" href="https://t.me/alexdracing" target="_blank"></a></li>
                        <li><a aria-current="page" className="youtube active" href="https://www.youtube.com/@AlexDProDrift" target="_blank"></a></li>
                        <li><a aria-current="page" className="rt active" href="https://rtd.rt.com/films/come-drift-with-me/" target="_blank"></a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};


export default MainMenu;